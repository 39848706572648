import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from "../utils/LoadingSpinner";

import { MouseEventHandler } from 'react';

function AuthedElement({ element }: any) {
  const loc = window.location;

  const ProtectedElement = withAuthenticationRequired(element, {
    onRedirecting: () => <LoadingSpinner />,
    returnTo: loc.pathname + loc.search + loc.hash,
  });

  const { isAuthenticated, loginWithPopup } = useAuth0();

  const handleLoginClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    loginWithPopup();
  };

  const isIframe = window.parent !== window.top;
  if (isIframe && !isAuthenticated) {
    return (
      <button className="logout-btn" onClick={handleLoginClick} title="Login" tabIndex={0}>
        Login
      </button>
    );
  }

  return <ProtectedElement />;
}

export default AuthedElement;
