import { getState } from "../utils/states";
import * as pagesAPI from "./pagesRoutes";
import * as searchAPI from "./searchRoutes";
import * as userAPI from "./userRoutes";

import axios, { AxiosError, AxiosResponse } from "axios";
import { EndpointConfig } from "./types";

const schemaList = [userAPI, searchAPI, pagesAPI];

const apiData = {};

function auth(token: string, instance = axios) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

function deAuth(instance = axios) {
  delete instance.defaults.headers.common["Authorization"];
}

function errorHandle(error: AxiosError, message = "Failure") {
  const snackbar = getState("snackbar");
  snackbar(message, { variant: "error" });
  return error;
}

function successHandle(response: AxiosResponse, message = "Success") {
  const snackbar = getState("snackbar");
  snackbar(message, { variant: "success" });
  return response;
}

function handle(
  response: any,
  defaultHandling: string,
  successMessage: string,
  errorMessage: string
) {
  if (defaultHandling === "all") {
    return response.data
      .then((response: AxiosResponse) => {
        return successHandle(response, successMessage);
      })
      .catch((error: AxiosError) => {
        return errorHandle(error, errorMessage);
      });
  } else if (defaultHandling === "error") {
    return response.data.catch((error: AxiosError) => {
      return errorHandle(error, errorMessage);
    });
  } else {
    return response;
  }
}

function query(endpointConfig: EndpointConfig, defaultHandling = "none") {
  let api = endpointConfig.instance ? endpointConfig.instance : axios;
  if (endpointConfig.protected) {
    auth(localStorage.getItem("token")!, api);
  }
  return handle(
    api.request(endpointConfig.json),
    defaultHandling,
    endpointConfig.success!,
    endpointConfig.error!
  ).finally(() => deAuth(api));
}

schemaList.forEach((schema) => {
  Object.entries(schema).forEach((endpoint) => {
    let [name, config] = endpoint;
    // return a function that will format the input data custom to each endpoint
    // defaultHandling takes either "all", "error", or "none"
    (apiData as any)[name] = (payload: any, defaultHandling: string) =>
      query(config(payload), defaultHandling);
  });
});

export default apiData;
