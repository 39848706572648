import { useAuth0 } from "@auth0/auth0-react";
import magnifyIcon from "../images/magnify-logo.svg";

import "./AccessDenied.scss";

const AccessDenied = () => {
  const { logout } = useAuth0();

  function renderLogoutButton() {
    return (
      <button
        className="route-btn"
        onClick={() =>
          logout({
            logoutParams: {
              returnTo: process.env.REACT_APP_LOGOUT_URL!,
            }
          })
        }
        title="Logout"
        tabIndex={0}
      >
        Return to Login
      </button>
    );
  }

  return (
    <>
      <div className="topbar">
        <div className="logo">
          <img className="icon" src={magnifyIcon} alt="Logo icon" />
        </div>
      </div>
      <div className="message">
        You do not have the required permissions to access Magnify:
        {renderLogoutButton()}
      </div>
    </>
  );
};

export default AccessDenied;
