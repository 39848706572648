import { AnyAction } from "redux";
import {
  SEARCHKIT_CHANGE_INDEX,
  SEARCHKIT_CHANGE_URL,
  SEARCHKIT_LISTEN,
  SEARCHKIT_UNLISTEN,
  SEARCHKIT_UPDATE,
} from "./types";

//TODO: define type, remove any
export const createSearchkitMiddleware = (searchkit: any) => {
  let _searchkitUnlisten: Function;

  function handleAction(action: AnyAction, next: Function, { dispatch }: any)
    {
      switch (action.type) {
        case SEARCHKIT_LISTEN: {
          if (_searchkitUnlisten) break;

          let searchkitListener = 

          _searchkitUnlisten = searchkit.emitter.addListener(() => {
            if (searchkit.loading) {
              // remove leading slash from url
              const url = searchkit.buildSearchUrl().substring(1);
              dispatch({ type: SEARCHKIT_UPDATE, payload: { url } });
            }
          });
          break;
        }

        case SEARCHKIT_CHANGE_URL: {
          const { url } = action.payload;
          searchkit.history.push(url);
          searchkit.searchFromUrlQuery(url);
          break;
        }

        case SEARCHKIT_CHANGE_INDEX: {
          const { path } = action.payload;
          const { transport } = searchkit;

          transport.options.indexPath = path;

          if (action.reload) {
            searchkit.reloadSearch();
          }
          break;
        }

        case SEARCHKIT_UNLISTEN: {
          if (_searchkitUnlisten) {
            _searchkitUnlisten();
          }
          break;
        }

        default:
          break;
      }

      return next(action);
    };

  return ({ dispatch }: any) =>
    (next: Function) => 
    (action: AnyAction) => handleAction(action, next,{ dispatch });
};

export default createSearchkitMiddleware;